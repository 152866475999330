import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'read-more',
  template: `
      <div [class.collapsed]="isCollapsed">
          <ng-content></ng-content>
      </div>
      <a href="javascript:void(0)" class="toggle-readmore" (click)="isCollapsed = !isCollapsed">Read {{isCollapsed ? 'more' : 'less'}}</a>
  `,
  styles: [`
      div.collapsed {
          height: 12rem;
          overflow: hidden;
      }
  `]
})

export class ReadMoreComponent {
  isCollapsed = true;
}