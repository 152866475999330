<app-header [currentActive]="currentActive" (scrollToElement)="scrollToElement($event)"></app-header>

<section class="banner-section bg-primary jumbotron text-white">
  <div class="container">
    <div class="text-block">
      <h1>Lane Gate Advisors, LP</h1>
      <p>
        Founded in 2018, Lane Gate Advisors, LP (“Lane Gate”) is an investment manager based in Brooklyn, NY focused
        on
        providing investment management services to entities that invest in specific alternative assets with a focus
        on
        uncorrelated sectors.
      </p>
    </div>
  </div>
</section>

<section id="about" #aboutUsSection class="jumbotron">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 about-thumb text-right">
        <img src="assets/images/about-us.png" />
      </div>

      <div class="col-lg-8">
        <div class="content-block aboutp">
          <h2>About</h2>
          <p class="lead">
            Lane Gate Advisors is a boutique investment management practice focused on certain segments of the specialty
            finance market. The Principals of Lane Gate have deep experience in all sides of the capital and life
            settlements markets, ranging from product structuring, development and marketing to raising and deploying
            capital. Our industry background and relationships are deep and complementary. Lane Gate works with both HNW
            and institutional investors to tailor investment strategies that best fit their goals.
          </p>
          <div class="aima-logo">
              <img src="../assets/images/about-us-logo.jpg">
              <p><strong> Lane Gate Advisors, LP is a member of <br> The Alternative Investment Management Association</strong></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="team" #teamSection class="jumbotron whiteBg">
  <div class="container">
    <h2>Senior Team</h2>
    <div class="row">
      <div class="col-lg-6 left-member">
        <div class="media">
          <img class="mr-3" src="assets/images/team/michael.png" alt="Michael">
          <div class="media-body">
            <h5 class="mt-0">Michael T. Crane</h5>
            <p>Co-Founder, Managing Principal and<br /> Co-Chief Investment Officer</p>
          </div>
        </div>

        <read-more>
          <p>Michael Crane has over three decades of experience in the capital markets in both the New York and London
            markets. While with established firms and de novo operations, Mr. Crane has had a leading or senior role in
            the raising or trading of billions of dollars in capital, ranging from corporate equity and debt to asset
            backed securities, derivatives, preferred stock and the full spectrum of unsecured and secured debt
            financing. After receiving his undergraduate degree in economics and computer science from Harvard, he began
            his career in Corporate Finance at The First Boston Corporation. After eight years with First Boston, he
            held senior positions at Barclays Capital, Smith Barney, General Re Financial Products and Deutsche Bank in
            debt capital markets structuring and distribution roles.</p>
          <p>He has been a pioneer in the longevity markets since he led the market’s first institutional equity capital
            allocation to life settlements while with Gen Re Financial Products, a subsidiary of Berkshire Hathaway.
            After conceiving of, structuring and obtaining all approvals for the investment, Mr. Crane closed two
            separate secondary market ‘flow’ life settlement transactions for Gen Re in the summer of 2001. In 2003, Mr.
            Crane was recruited by Deutsche Bank AG to establish a life settlements principal trading business in
            London, where he and his team traded over $2B (investment amount) of longevity-linked transactions globally.
          </p>
          <p>After Deutsche Bank, Mr. Crane was recruited to be the first employee and CEO of an FSA regulated boutique
            securities firm focussed on insurance linked exposures in London, UK, managing 25 staff based in offices in
            London, Hong Kong, Sydney and Tokyo. He has been responsible for a number of key industry achievements
            throughout his career, including the first three successful securitization of time share loans, first
            securitization of Small Business Administration Sec. 504 loans, the largest annual placement of MBS backed
            EMTNs for a US savings and loan, the first and the largest agency backed securitization of manufactured
            housing loans ($1B), and the first mandates globally for synthetic life settlement exposure from a European
            corporate pension fund and also from an Australian superannuation fund, among others. He has been a frequent
            speaker at industry conferences around the world.</p>
        </read-more>

      </div>
      <div class="col-lg-6 right-member col-mt-4">
        <div class="media">
          <img class="mr-3" src="assets/images/team/bryan.png" alt="Brayan">
          <div class="media-body">
            <h5 class="mt-0">Bryan Cooper</h5>
            <p>Co-Founder, Managing Principal and<br /> Co-Chief Investment Officer</p>
          </div>
        </div>

        <read-more>
          <p>Bryan Cooper has over 15 years of investing and insurance related experience. He began his capital markets
            career in 2003 as a marketing rep for Family Protection Group where he focused on life and annuity products
            for the senior market. Within the first year, he was one of the top producers in the company and was asked
            to join as a partner. From 2005 through 2011 he was responsible for over 100 producing agents and oversaw
            product construction, training, and sales in the senior product and life settlements markets. After 6 years
            in that position, Mr. Cooper decided to focus primarily on the life settlement market and teamed up with a
            boutique life settlement firm that focused on mainly long term investments. In 2013, Mr. Cooper realized the
            opportunity that the short term and viatical settlements market could bring to his clients and made the
            switch from focusing on long term investments to short term and viatical policies with excellent results. He
            has been responsible for investing over $50 million in short term and viatical policy investments over the
            last 5 years and has produced attractive double digit returns for his investors.</p>
        </read-more>
      </div>
    </div>
  </div>
</section>

<section id="contact" #contactSection class="jumbotron mb-0">
  <div class="container">
    <h2 class="mb-5 ">Contact Us</h2>

    <div class="row">
      <div class="col-lg-6">
        <div class="contact-thumb">
          <img src="assets/images/contact-us.png" class="img-fluid" />
        </div>
      </div>

      <div class="col-lg-6">
        <div class="contact-text-block">
          <p><strong>If you would like to learn more about our services</strong></p>
          <p>
            Please email <a
              href="mailto:investorservices@lanegateadvisors.com">investorservices@lanegateadvisors.com</a> <br />
            or call us at <a href="tel:+16467622800">+1 646 762 2800</a> during business hours.
          </p>
        </div>

        <div class="contact-text-block">
          <p><strong>Our address for meetings and couriers is:</strong></p>
          <p>
            Lane Gate Advisors, LP <br />
            155 Water Street, Suite 6/20 <br />
            Brooklyn, NY 11201 USA
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>