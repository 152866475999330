import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() currentActive: number = 0;
  @Output() scrollToElement = new EventEmitter<number>();

  navbarOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }


  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  moveToSection(currentActive) {
    this.scrollToElement.emit(currentActive);
    this.toggleNavbar();
  }

}
