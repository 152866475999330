import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('aboutUsSection') aboutUsSection: ElementRef;
  @ViewChild('teamSection') teamSection: ElementRef;
  @ViewChild('contactSection') contactSection: ElementRef;

  public currentActive = 0;
  public aboutUsSectionOffset: Number = null;
  public teamSectionOffset: Number = null;
  public contactSectionOffset: Number = null;

  @HostListener('window:scroll', ['$event'])
  checkOffsetTop() {
    this.aboutUsSectionOffset = this.aboutUsSection.nativeElement.offsetTop - this.topNavHeight;
    this.teamSectionOffset = this.teamSection.nativeElement.offsetTop - this.topNavHeight;
    this.contactSectionOffset = this.contactSection.nativeElement.offsetTop - this.topNavHeight;

    if (window.pageYOffset >= this.aboutUsSectionOffset && window.pageYOffset < this.teamSectionOffset) {
      this.currentActive = 1;
    } else if (window.pageYOffset >= this.teamSectionOffset && window.pageYOffset < this.contactSectionOffset) {
      this.currentActive = 2;
    } else if (window.pageYOffset >= this.contactSectionOffset) {
      this.currentActive = 3;
    } else {
      this.currentActive = 0;
    }
  }

  scrollToElement(sectionNumber: number) {
    if (sectionNumber <= 0) {
      window.scroll({ top: 0, behavior: 'smooth' });
    } else {
      const target = this.sectionByNumber(sectionNumber);
      const scrollableElement = target.nativeElement ? target.nativeElement : target;

      var topheight = scrollableElement.offsetTop - this.topNavHeight;
      window.scroll({ top: topheight, behavior: 'smooth' });
    }

  }

  sectionByNumber(sectionNumber: number) {
    switch (sectionNumber) {
      case 1: return this.aboutUsSection;
      case 2: return this.teamSection;
      default: return this.contactSection;
    }
  }


  ngOnInit(): void {

  }

  get topNavHeight() {
    return (document.getElementById('topNavigation') as HTMLElement).offsetHeight;
  }
}
