<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="topNavigation">
    <div class="container">
        <a class="navbar-brand js-scroll-trigger" (click)="moveToSection(0)">
            <img src="assets/images/logo.png" width="150" />
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" [class.active]="currentActive === 1">
                    <a class="nav-link js-scroll-trigger" href="javascript:void(0)" (click)="moveToSection(1)">About</a>
                </li>
                <li class="nav-item" [class.active]="currentActive === 2">
                    <a class="nav-link js-scroll-trigger" href="javascript:void(0)" (click)="moveToSection(2)">Team</a>
                </li>
                <li class="nav-item" [class.active]="currentActive === 3">
                    <a class="nav-link js-scroll-trigger" href="javascript:void(0)" (click)="moveToSection(3)">Contact</a>
                </li>
            </ul>
        </div>
    </div>
</nav>